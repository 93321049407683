

// import React, { useState, useEffect } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import {
//   Container,
//   Typography,
//   Grid,
//   Button,
//   Table,
//   TableBody,
//   TableCell,
//   TableRow,
//   TableHead,
//   Paper,
//   Snackbar,
//   Alert,
//   CircularProgress,
// } from '@mui/material';
// import { getAvailableSlots, makeReservation, getBalance } from '../services/reservationService';
// import { format, addMinutes } from 'date-fns';

// function SlotSelectionPage() {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const { selectedHorse, selectedDate } = location.state || {};

//   const [slots, setSlots] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [selectedSlot, setSelectedSlot] = useState(null);
//   const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
//   const [reservationLoading, setReservationLoading] = useState(false);
//   const [balance, setBalance] = useState(0);

//   useEffect(() => {
//     if (selectedHorse && selectedDate) {
//       fetchSlots();
//       fetchBalance();

//       // Set up polling every minute
//       const interval = setInterval(() => {
//         fetchSlots();
//         fetchBalance();
//       }, 60000); // 60,000 ms = 1 minute

//       // Clean up interval on component unmount
//       return () => clearInterval(interval);
//     } else {
//       navigate('/reserve/select-horse');
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [selectedHorse, selectedDate]);

//   const fetchSlots = async () => {
//     setLoading(true);
//     try {
//       const response = await getAvailableSlots(selectedHorse.HorseID, selectedDate);
//       setSlots(response.data); 
//     } catch (error) {
//       console.error('Error fetching slots:', error);
//       showSnackbar('Failed to fetch slots.', 'error');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const fetchBalance = async () => {
//     try {
//       const response = await getBalance();
//       setBalance(response.data.balance);
//     } catch (error) {
//       console.error('Error fetching balance:', error);
//       showSnackbar('Failed to fetch balance.', 'error');
//     }
//   };

//   // Generate slots between 08:00 AM and 10:00 PM, each 40 minutes long with a 5-minute gap
//   const generateTimeSlots = () => {
//     const slotsArray = [];
//     let current = new Date(`1970-01-01T08:00:00Z`);
//     const end = new Date(`1970-01-01T22:00:00Z`);

//     while (current < end) {
//       const slotEnd = addMinutes(current, 40);
//       slotsArray.push({
//         time: format(current, 'HH:mm:ss'),
//         endTime: format(slotEnd, 'HH:mm:ss'),
//         status: 'available',
//       });
//       current = addMinutes(slotEnd, 5); // 5-minute gap
//     }

//     return slotsArray;
//   };

//   // Merge generated slots with booked slots from backend
//   const mergeSlots = () => {
//     const generatedSlots = generateTimeSlots();
//     const bookedSlots = slots; 

//     const merged = generatedSlots.map((slot) => ({
//       ...slot,
//       status: bookedSlots.includes(slot.time) ? 'booked' : 'available',
//     }));

//     return merged;
//   };

//   const handleSelectSlot = (slot) => {
//     if (slot.status === 'booked') return; // Prevent selecting booked slots
//     setSelectedSlot(slot);
//   };

//   const handleReserve = async () => {
//     if (!selectedSlot) {
//       showSnackbar('Please select a slot to reserve.', 'warning');
//       return;
//     }

//     // Check balance
//     if (balance < selectedHorse.Cost) {
//       showSnackbar('Insufficient balance to make this reservation.', 'error');
//       return;
//     }

//     setReservationLoading(true);

//     try {
//       const reservationData = {
//         date: selectedDate,
//         time: selectedSlot.time,
//         horseId: selectedHorse.HorseID,
//       };
//       await makeReservation(reservationData);
//       showSnackbar('Reservation successful!', 'success');
//       fetchSlots(); // Refresh slots to show newly booked slot
//       fetchBalance(); 
//       setSelectedSlot(null);
//       // Optionally navigate to a confirmation page:
//       // navigate('/confirmation', { state: { reservation: reservationData, selectedHorse } });
//     } catch (error) {
//       console.error('Error making reservation:', error);
//       const errorMsg = error.response?.data || 'Failed to make reservation.';
//       showSnackbar(errorMsg, 'error');
//     } finally {
//       setReservationLoading(false);
//     }
//   };

//   const handleBack = () => {
//     navigate('/reserve/select-date', { state: { selectedHorse, selectedDate } });
//   };

//   const showSnackbar = (message, severity) => {
//     setSnackbar({ open: true, message, severity });
//   };

//   const handleSnackbarClose = () => setSnackbar({ ...snackbar, open: false });

//   if (!selectedHorse || !selectedDate) {
//     navigate('/reserve/select-horse');
//     return null;
//   }

//   const showReserveButtonOnTop = selectedSlot !== null;

//   return (
//     <Container sx={{ mt: 4 }}>
//       <Typography variant="h4" align="center" gutterBottom>
//         Select a Time Slot
//       </Typography>
//       <Typography variant="h6" align="center" gutterBottom>
//         Horse: {selectedHorse.HorseName} | Cost: ${selectedHorse.Cost.toFixed(2)}
//       </Typography>
//       <Typography variant="subtitle1" align="center" gutterBottom>
//         Date: {format(new Date(selectedDate), 'MMMM dd, yyyy')}
//       </Typography>
//       <Typography variant="subtitle1" align="center" gutterBottom>
//         Current Balance: ${balance.toFixed(2)}
//       </Typography>

//       {/* If a slot is selected, show the Reserve button at the top */}
//       {showReserveButtonOnTop && (
//         <Grid container justifyContent="center" sx={{ mt: 2, mb: 2 }}>
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={handleReserve}
//             disabled={!selectedSlot || reservationLoading}
//           >
//             {reservationLoading ? <CircularProgress size={24} color="inherit" /> : 'Reserve'}
//           </Button>
//         </Grid>
//       )}

//       <Grid container justifyContent="center" sx={{ mt: 2 }}>
//         {loading ? (
//           <CircularProgress />
//         ) : (
//           <Paper sx={{ width: '100%', overflow: 'hidden' }}>
//             <Table>
//               <TableHead>
//                 <TableRow>
//                   <TableCell align="center">Time Slot</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {mergeSlots().map((slot) => (
//                   <TableRow
//                     key={slot.time}
//                     sx={{
//                       backgroundColor:
//                         slot.status === 'booked'
//                           ? 'error.light'
//                           : selectedSlot?.time === slot.time
//                           ? 'success.light'
//                           : 'background.paper',
//                       cursor: slot.status === 'available' ? 'pointer' : 'not-allowed',
//                     }}
//                     onClick={() => handleSelectSlot(slot)}
//                   >
//                     <TableCell align="center">
//                       {format(new Date(`1970-01-01T${slot.time}`), 'hh:mm a')} -{' '}
//                       {format(new Date(`1970-01-01T${slot.endTime}`), 'hh:mm a')}
//                     </TableCell>
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </Paper>
//         )}
//       </Grid>
//       <Grid container justifyContent="space-between" sx={{ mt: 4 }}>
//         <Button variant="outlined" color="primary" onClick={handleBack}>
//           Back
//         </Button>
//         {/* We remove the Reserve button from here since we put it on top when a slot is selected */}
//       </Grid>

//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={handleSnackbarClose}
//         anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
//       >
//         <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Container>
//   );
// }

// export default SlotSelectionPage;



import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Paper,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import { getAvailableSlots, makeReservation, getBalance } from '../services/reservationService';
import { format, addMinutes } from 'date-fns';

function SlotSelectionPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedHorse, selectedDate } = location.state || {};

  const [slots, setSlots] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [reservationLoading, setReservationLoading] = useState(false);
  const [balance, setBalance] = useState(0);

  // Define a weekly schedule for available slots.
  // Adjust start, end, slotDuration (in minutes) and gap (in minutes) as needed.
  const weeklySchedule = {
    Sunday: { start: '10:00:00', end: '18:30:00', slotDuration: 40, gap: 5 },
    Monday: null,//closed
    Tuesday: { start: '14:00:00', end: '18:30:00', slotDuration: 40, gap: 5 },
    Wednesday: { start: '10:00:00', end: '14:00:00', slotDuration: 40, gap: 5 },
    Thursday: { start: '14:00:00', end: '18:30:00', slotDuration: 40, gap: 5 },
    Friday: { start: '10:00:00', end: '14:00:00', slotDuration: 40, gap: 5 },
    Saturday: { start: '10:00:00', end: '18:30:00', slotDuration: 40, gap: 5 },
  };

  // Convert a time string (HH:mm:ss) into a Date object on a fixed date (e.g., 1970-01-01)
  const timeStringToDate = (timeStr) => new Date(`1970-01-01T${timeStr}`);

  // Generate slots based on the selected date's day of week using the weekly schedule.
  const generateTimeSlots = () => {
    if (!selectedDate) return [];
    
    // Get day of week name (e.g., "Monday")
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayName = days[new Date(selectedDate).getDay()];

    const schedule = weeklySchedule[dayName];
    if (!schedule) return []; // closed if no schedule for the day

    const { start, end, slotDuration, gap } = schedule;
    const startTime = timeStringToDate(start);
    const endTime = timeStringToDate(end);

    // If startTime >= endTime, treat day as closed
    if (startTime >= endTime) return [];

    const slotsArray = [];
    let current = startTime;

    while (current.getTime() + slotDuration * 60000 <= endTime.getTime()) {
      const slotEnd = addMinutes(current, slotDuration);
      slotsArray.push({
        time: format(current, 'HH:mm:ss'),
        endTime: format(slotEnd, 'HH:mm:ss'),
        status: 'available',
      });
      // Move to next slot: slotEnd + gap minutes
      current = addMinutes(slotEnd, gap);
    }
    return slotsArray;
  };

  const fetchSlots = async () => {
    setLoading(true);
    try {
      // In this example, we assume getAvailableSlots returns an array of booked slot times (as 'HH:mm:ss')
      // for the selected horse and date.
      const response = await getAvailableSlots(selectedHorse.HorseID, selectedDate);
      setSlots(response.data);
    } catch (error) {
      console.error('Error fetching slots:', error);
      showSnackbar('Failed to fetch slots.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const fetchBalance = async () => {
    try {
      const response = await getBalance();
      setBalance(response.data.balance);
    } catch (error) {
      console.error('Error fetching balance:', error);
      showSnackbar('Failed to fetch balance.', 'error');
    }
  };

  useEffect(() => {
    if (selectedHorse && selectedDate) {
      fetchSlots();
      fetchBalance();
      // Polling every minute
      const interval = setInterval(() => {
        fetchSlots();
        fetchBalance();
      }, 60000);
      return () => clearInterval(interval);
    } else {
      navigate('/reserve/select-horse');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHorse, selectedDate]);

  // Merge generated slots with booked slots from backend
  const mergeSlots = () => {
    const generatedSlots = generateTimeSlots();
    const bookedSlots = slots; // booked slot times as array of 'HH:mm:ss' strings

    return generatedSlots.map((slot) => ({
      ...slot,
      status: bookedSlots.includes(slot.time) ? 'booked' : 'available',
    }));
  };

  const handleSelectSlot = (slot) => {
    if (slot.status === 'booked') return; // Prevent selecting booked slots
    setSelectedSlot(slot);
  };

  const handleReserve = async () => {
    if (!selectedSlot) {
      showSnackbar('Please select a slot to reserve.', 'warning');
      return;
    }
    if (balance < selectedHorse.Cost) {
      showSnackbar('Insufficient balance to make this reservation.', 'error');
      return;
    }
    setReservationLoading(true);
    try {
      const reservationData = {
        date: selectedDate,
        time: selectedSlot.time,
        horseId: selectedHorse.HorseID,
      };
      await makeReservation(reservationData);
      showSnackbar('Reservation made successfully!', 'success');
      fetchSlots();
      fetchBalance();
      setSelectedSlot(null);
      // Optionally navigate to a confirmation page:
      // navigate('/confirmation', { state: { reservation: reservationData, selectedHorse } });
    } catch (error) {
      console.error('Error making reservation:', error);
      const errorMsg = error.response?.data || 'Failed to make reservation.';
      showSnackbar(errorMsg, 'error');
    } finally {
      setReservationLoading(false);
    }
  };


  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Select a Time Slot
      </Typography>
      <Typography variant="h6" align="center" gutterBottom>
        Horse: {selectedHorse.HorseName} | Cost: ${selectedHorse.Cost.toFixed(2)}
      </Typography>
      <Typography variant="subtitle1" align="center" gutterBottom>
        Date: {format(new Date(selectedDate), 'MMMM dd, yyyy')}
      </Typography>
      <Typography variant="subtitle1" align="center" gutterBottom>
        Current Balance: ${balance.toFixed(2)}
      </Typography>
      {loading ? (
        <Grid container justifyContent="center" sx={{ mt: 2 }}>
          <CircularProgress />
        </Grid>
      ) : (
        <Paper sx={{ width: '100%', overflow: 'hidden', mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Time Slot</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mergeSlots().map((slot) => (
                <TableRow
                  key={slot.time}
                  sx={{
                    backgroundColor:
                      slot.status === 'booked'
                        ? 'error.light'
                        : selectedSlot?.time === slot.time
                        ? 'success.light'
                        : 'background.paper',
                    cursor: slot.status === 'available' ? 'pointer' : 'not-allowed',
                  }}
                  onClick={() => handleSelectSlot(slot)}
                >
                  <TableCell align="center">
                    {format(new Date(`1970-01-01T${slot.time}`), 'hh:mm a')} -{' '}
                    {format(new Date(`1970-01-01T${slot.endTime}`), 'hh:mm a')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}
      <Grid container justifyContent="space-between" sx={{ mt: 4 }}>
        <Button variant="outlined" color="primary" onClick={() => navigate('/reserve/select-date')}>
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleReserve}
          disabled={!selectedSlot || reservationLoading}
        >
          {reservationLoading ? <CircularProgress size={24} color="inherit" /> : 'Reserve'}
        </Button>
      </Grid>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default SlotSelectionPage;
